<template>
    <div class="card-box">
        <div class="float-right">
            <svg :id="`barcode_${orderProductColor.id}_${index}`" class="barcode" />
        </div>
        <h3 class="font-weight-bold">
            Work Instruction Sheet
        </h3>

        <div class="row mt-3">
            <div class="col-sm-6">
                <p>
                    Order Number:
                    <mark>
                        <b>
                            {{
                                orderProductColor.order.order_number
                            }}
                            |
                            {{ indexOffset + index }}
                        </b>
                    </mark>
                </p>
                <p>
                    Customer Name:
                    <mark>
                        <b>
                            {{
                                `${
                                    orderProductColor.order.customer
                                        .first_name
                                } ${
                                    orderProductColor.order.customer
                                        .last_name
                                }`
                            }}
                        </b>
                    </mark>
                </p>
                <p>
                    Gender:
                    <mark>
                        <b>{{ genderText }}</b>
                    </mark>
                </p>
                <hr class="w-50 ml-0" />
            </div>
            <div class="col-sm-6 mt-2">
                <p>
                    Model Name:
                    <b>
                        {{
                            orderProductColor.productColor.product
                                .name
                        }}
                    </b>
                    <b v-if="orderProductColor.productColor.product.hebrew_name">
                        -
                        <mark>{{
                            orderProductColor.productColor.product
                                .hebrew_name
                        }}</mark>
                    </b>
                </p>
                <p>
                    Last customer's order no.:
                    <b>{{
                        orderProductColor.order.customer
                            .previous_order_number
                    }}</b>
                </p>
            </div>

            <div class="col-sm-6">
                <p>
                    Order Date:
                    <b>{{
                        orderProductColor.order.created_at
                            | formatDateOnly
                    }}</b>
                </p>
                <p>
                    Shell Material:
                    <mark>
                        <b>{{ instruction.shell_material }}</b>
                    </mark>
                </p>
                <p>
                    Original design date:
                    <b>{{
                        instruction.created_at | formatDateOnly
                    }}</b>
                </p>
                <p>
                    Scan Date:
                    <b></b>
                </p>
                <p>
                    Ship Date:
                    <b>{{ shippingDate | formatDateOnly }}</b>
                </p>
                <p>
                    Number of designs for this order:
                    <b>{{ orderProductColor.order.orderProductColor.length }}</b>
                </p>
                <p>
                    No of orthotics of this design:
                    <b>{{ orderProductColor.quantity }}</b>
                </p>
            </div>

            <div class="col-sm-6" style="font-size: 18px">
                <p>
                    <template
                        v-for="(shellMaterial, mIndex) of shellMaterials"
                    >
                        <span
                            v-if="indexOffset + index !== shellMaterial.index"
                            :key="mIndex"
                        >
                            <mark>{{ shellMaterial.index }}</mark> |
                            <b>{{ shellMaterial.value }}</b>
                            &nbsp;
                        </span>
                    </template>
                </p>

                <template v-for="(sameShellMaterials, mIndex) of sortedShellMaterials">
                    <p
                        v-if="sameShellMaterials.length > 1"
                        :key="mIndex"
                    >
                        <mark>
                            <span
                                v-for="(shellMaterial, sIndex) of sameShellMaterials"
                                :key="sIndex"
                            >
                                <span v-if="sIndex">+</span>
                                {{ shellMaterial.index }}
                            </span>
                        </mark>
                        &nbsp;
                        <b>זהים</b>
                    </p>
                </template>
            </div>

            <div class="col-12">
                <hr />
            </div>

            <div class="col-sm-6">
                <p>
                    Type:
                    <b></b>
                </p>
                <p>
                    Brand:
                    <b></b>
                </p>
                <p>
                    Size US:
                    <mark>
                        <b>{{ orderProductColor.size }}</b>
                    </mark>
                </p>
                <p>
                    Size EU:
                    <b>{{ euSize }}</b>
                </p>
                <p>
                    Photos available:
                    <b>
                        {{
                            orderProductColor.images.length
                                ? `Yes (${orderProductColor.images.length})`
                                : 'No'
                        }}
                    </b>
                </p>
            </div>

            <div
                v-if="
                    orderProductColor.order.orderSecondaryProduct
                        .length
                "
                class="col-sm-6"
            >
                <h5
                    class="text-uppercase text-center text-decoration-underline mt-0"
                >
                    Other Products:
                </h5>
                <p
                    v-for="(orderSecondaryProduct, sIndex) in orderProductColor.order.orderSecondaryProduct"
                    :key="sIndex"
                >
                    <mark class="mark_orange">
                        {{
                            orderSecondaryProduct.secondaryProduct
                                .sku
                        }}:
                        <b>{{ orderSecondaryProduct.quantity }}</b>
                    </mark>
                </p>
            </div>

            <div class="col-12">
                <hr />
            </div>

            <div class="col-sm-12">
                <p>
                    Heel thickness (mm):
                    <b>{{ instruction.heel_thickness }}</b>
                </p>
                <p>
                    Forefoot thickness (mm):
                    <b>{{ instruction.forefoot_thickness }}</b>
                </p>
                <p>
                    Width:
                    <b>{{ instruction.width }}</b>
                </p>
            </div>

            <div class="col-12">
                <hr />
            </div>

            <div class="col-sm-6">
                <h5
                    class="text-uppercase text-center text-decoration-underline mt-0"
                >
                    Measurments:
                </h5>
                <div class="row">
                    <div class="col-12">
                        <p>
                            L (mm):
                            <b>{{ instruction.heel_length }}</b>
                        </p>
                        <p>
                            FF (mm):
                            <b>{{ instruction.forefoot_width }}</b>
                        </p>
                        <p>
                            H (mm):
                            <b>{{ instruction.heel_width }}</b>
                        </p>
                        <p>
                            A (mm):
                            <b>{{ instruction.arch_height }}</b>
                        </p>
                    </div>
                    <div class="col-5">
                        <p>
                            Big arch left (mm):
                            <b>{{ instruction.big_arch_left }}</b>
                        </p>
                        <p>
                            Small arch left (mm):
                            <b>{{ instruction.small_arch_left }}</b>
                        </p>
                        <p>
                            Draft:
                            <b>{{ instruction.draft }}</b>
                        </p>
                        <p>
                            Arch extra width:
                            <b>{{ instruction.arch_extra_width }}</b>
                        </p>
                    </div>
                    <div class="col-7">
                        <p>
                            Big arch right (mm):
                            <b>{{ instruction.big_arch_right }}</b>
                        </p>
                        <p>
                            Small arch right (mm):
                            <b>{{ instruction.small_arch_right }}</b>
                        </p>
                        <p>
                            &nbsp;
                        </p>
                        <p>
                            Extra draft to arch:
                            <b>{{ instruction.arch_extra_draft }}</b>
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-left-border">
                <h5 class="text-uppercase text-center mt-0">
                    Grinding:
                </h5>
                <p>
                    New grinding:
                    <b>{{ instruction.new_grinding }}</b>
                </p>
                <p>
                    A (mm):
                    <b v-if="instruction.heel_length">
                        {{ (instruction.heel_length * 0.35).toFixed(2) }}
                    </b>
                </p>
                <p>
                    B (mm):
                    <b v-if="instruction.heel_length">
                        {{ (instruction.heel_length * 0.85).toFixed(2) }}
                    </b>
                </p>
                <p>
                    C (mm):
                    <b v-if="instruction.heel_length">
                        {{ (instruction.heel_length * 0.75).toFixed(2) }}
                    </b>
                </p>
            </div>

            <div class="col-12">
                <hr />
            </div>

            <div class="col-6">
                <h5 class="text-uppercase text-center mt-0">
                    Padding Type:
                </h5>
                <div class="row">
                    <div class="col-5">
                        <p>
                            MB:
                            <b>{{ instruction.padding_type_mb }}</b>
                        </p>
                        <p>
                            HH:
                            <b>{{ instruction.padding_type_hh }}</b>
                        </p>
                        <p>
                            HP:
                            <b>{{ instruction.padding_type_hp }}</b>
                        </p>
                        <p>
                            1M:
                            <b>{{ instruction.padding_type_1m }}</b>
                        </p>
                    </div>
                    <div class="col-7">
                        <p>
                            Arch pad:
                            <b>{{ instruction.padding_type_arch_pad }}</b>
                        </p>
                        <p>
                            Met domes:
                            <b>{{ instruction.padding_type_met_domes }}</b>
                        </p>
                        <p>
                            Reverse Mortons:
                            <b>{{ instruction.padding_type_reverse_mortons }}</b>
                        </p>
                        <p>
                            Mortons Extension:
                            <b>{{ instruction.padding_type_mortons_extension }}</b>
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-6 col-left-border">
                <h5 class="text-uppercase text-center mt-0">
                    Padding Material:
                </h5>
                <div class="row">
                    <div class="col-5">
                        <p>
                            MB:
                            <b>{{ instruction.padding_material_mb }}</b>
                        </p>
                        <p>
                            HH:
                            <b>{{ instruction.padding_material_hh }}</b>
                        </p>
                        <p>
                            HP:
                            <b>{{ instruction.padding_material_hp }}</b>
                        </p>
                        <p v-if="instruction.padding_material_hp2">
                            HP2:
                            <b>{{ instruction.padding_material_hp2 }}</b>
                        </p>
                        <p v-if="instruction.padding_material_hp3">
                            HP3:
                            <b>{{ instruction.padding_material_hp3 }}</b>
                        </p>
                        <p>
                            1M:
                            <b>{{ instruction.padding_material_1m }}</b>
                        </p>
                    </div>
                    <div class="col-7">
                        <p>
                            Arch pad:
                            <b>{{ instruction.padding_material_arch_pad }}</b>
                        </p>
                        <p>
                            Met domes:
                            <b>{{ instruction.padding_material_met_domes }}</b>
                        </p>
                        <p>
                            Reverse Mortons:
                            <b>{{ instruction.padding_material_reverse_mortons }}</b>
                            <b v-if="instruction.heel_length && instruction.padding_material_reverse_mortons">
                                ({{ (instruction.heel_length * 0.2).toFixed(2) }})
                            </b>
                        </p>
                        <p>
                            Mortons Extension:
                            <b>{{ instruction.padding_material_mortons_extension }}</b>
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <hr />
            </div>

            <div class="col-6">
                <h5 class="text-uppercase text-center mt-0">
                    Midlayer & Top Cover:
                </h5>
                <p>
                    Midlayer Length:
                    <b>{{ instruction.midlayer_length }}</b>
                </p>
                <p>
                    Midlayer Material:
                    <b>{{ instruction.midlayer_material }}</b>
                </p>
                <p>
                    Top Cover Length:
                    <b>{{ instruction.top_cover_length }}</b>
                </p>
                <p>
                    Top Cover Material:
                    <b>{{ instruction.top_cover_material }}</b>
                </p>
                <p>
                    Extra Midlayer Material:
                    <b>{{ instruction.extra_midlayer }}</b>
                </p>
                <p>
                    Extra Midlayer Length:
                    <b>{{ instruction.extra_midlayer_length }}</b>
                </p>
            </div>

            <div class="col-6 col-left-border">
                <h5 class="text-uppercase text-center mt-0">
                    Extra Customer Comments:
                </h5>
                <p>{{ instruction.customer_info }}</p>
            </div>

            <div class="col-12">
                <hr />
            </div>

            <div class="col-6">
                <h5 class="text-uppercase text-center mt-0">
                    Designer Additional Info:
                </h5>
                <p>{{ instruction.designer_info }}</p>
                <p>&nbsp;</p>
                <p v-if="orderProductColor.assignee">
                    Designer Id:
                    <b>{{ orderProductColor.assignee.designer_id }}</b>
                </p>
            </div>

            <div class="col-6 col-left-border">
                <h5 class="text-uppercase text-center mt-0">
                    Lab's Remark:
                </h5>
                <p>{{ instruction.labs_remark }}</p>
            </div>
        </div>

        <div class="mt-4 mb-1">
            <div class="text-right d-print-none">
                <button
                    class="btn btn-primary"
                    @click="print"
                >
                    <i class="mdi mdi-printer mr-1" />
                    Print
                </button>
                <button
                    class="btn btn-success ml-1"
                    @click="edit"
                >
                    <i class="fe-edit mr-1" />
                    Edit
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import JsBarcode from 'jsbarcode';

export default {
    props: {
        orderProductColor: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        sizes: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            instruction: {}
        }
    },

    computed: {
        genderText() {
            const { genderIdentifier } = this.orderProductColor.account;

            if (genderIdentifier === 'gender_men') {
                return 'Men';
            }

            if (genderIdentifier === 'gender_women') {
                return 'Women';
            }

            return null;
        },

        euSize() {
            const size = this.sizes.find(
                size =>
                    Number(size.size) === Number(this.orderProductColor.size)
            );

            if (!size) {
                return '-';
            }

            if (this.genderText === 'Men') {
                return size.eu_size_men;
            } else {
                return size.eu_size_women;
            }
        },

        shippingDate() {
            const shippedStatus = this.orderProductColor.history.find(
                status => status.status === 'SHIP_TO_CUSTOMER'
            );

            if (!shippedStatus) {
                return '';
            }

            return shippedStatus.created_at;
        },

        indexOffset() {
            const items = [...this.orderProductColor.order.orderProductColor];

            const index = items.findIndex(
                product => product.id === this.orderProductColor.id
            );

            const itemsBefore = items.slice(0, index);

            return itemsBefore.reduce(
                (value, item) => value + item.quantity,
                0
            );
        },

        shellMaterials() {
            const shellMaterialList = [];

            for (const orderProductColor of this.orderProductColor.order.orderProductColor) {
                for (let i = 0; i < orderProductColor.quantity; i++) {
                    shellMaterialList.push(
                        orderProductColor.instruction
                            ? orderProductColor.instruction.shell_material
                            : null
                    );
                }
            }

            return shellMaterialList.map((item, index) => ({
                index: index + 1,
                value: item
            }));
        },

        sortedShellMaterials() {
            const filledShellMaterials = this.shellMaterials.filter(shellMaterial => shellMaterial.value);

            return filledShellMaterials.reduce((acc, obj) => {
                const found = acc.find(arr => arr.length > 0 && arr[0].value === obj.value);

                if (found) {
                    found.push(obj);
                } else {
                    acc.push([obj]);
                }

                return acc;
            }, []);
        }
    },

    created() {
        this.instruction = this.orderProductColor.instruction || {};
    },

    mounted() {
        this.initBarcodes();
    },

    methods: {
        initBarcodes() {
            for (let i = 0; i < this.orderProductColor.quantity; i++) {
                JsBarcode(
                    `#barcode_${this.orderProductColor.id}_${i + 1}`,
                    `${this.orderProductColor.order.order_number}|${
                        this.indexOffset + i + 1

                    }`,
                    {
                        displayValue: false,
                        height: 40
                    }
                );
            }
        },

        edit() {
            this.$emit('edit', this.orderProductColor.id);
        },

        print() {
            this.$emit('print', `${this.orderProductColor.id}_${this.index}`);
        }
    }
}
</script>

<style scoped lang="scss">
.printable_instruction {
    font-size: 16px;
    color: #343a40;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    page-break-after: always;

    p {
        margin: 0;
        padding: 0;
    }
}

.col-left-border {
    border-left: 1px solid #dee2e6;
}

mark {
    padding: 0 0.6rem;
    background-color: rgb(255, 255, 141);

    &.mark_orange {
        background-color: #ffbd76;
    }
}

hr {
    border-color: #dee2e6;
}
</style>
